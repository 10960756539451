<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-Register">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{$t('Register Account')}}</h4>
                  <p>Fill the below form to create a new account.</p>
                </div>

                <div>
                  <vs-input
                    name="email"
                    :label-placeholder="$t('Email')"
                    v-model="email"
                    class=" inputx w-full mb-10 mt-10"/>
                  <vs-button  @click="registerByEmail()" class="float-right mb-6">{{$t('Register')}}</vs-button>
                  <vs-button type="border" :to="{name:'login'}">{{$t('login')}}</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data: () => ({
    email:'',
  }),
  created () {
  },
  methods: {
    registerByEmail(){
      this.$http.post('/auth/register', {
        'RegisterType': 'email',
        'email': this.email
      }).then((response) => {
        this.$vs.notify({
          title: this.$t('Successfully'),
          text: this.$t('Email Has Been sent successfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
          position: 'top-center'
        })
      })
    }
  },

}
</script>

